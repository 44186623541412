/* Template: Aria - Business HTML Landing Page Template
   Author: Inovatik
   Created: Jul 2019
   Description: Custom JS file
*/

import $ from "jquery";
window.$ = window.jQuery = $;
import jQueryBridget from "jquery-bridget";
// const jQuery = $;
// window.$ = $;
// window.jQuery = jQuery;
import isotope from "isotope-layout";
import Swiper from "./swiper.min.js";
import magnificPopup from "./jquery.magnific-popup.js";
import easing from "./jquery.easing.min.js";
// import Morphext from './morphext.min.js';
import bootstrap from "./bootstrap.min.js";
// import validator from './validator.min.js';

export default function () {
  jQueryBridget("isotope", isotope, $);

  magnificPopup();
  // Morphext();
  easing();

  (function ($) {
    "use strict";

    /* Preloader */
    // $(window).on('load', function() {
    var preloaderFadeOutTime = 500;
    function hidePreloader() {
      var preloader = $(".spinner-wrapper");
      setTimeout(function () {
        preloader.fadeOut(preloaderFadeOutTime);
      }, 500);
    }
    hidePreloader();
    // });

    /* Navbar Scripts */
    // jQuery to collapse the navbar on scroll
    $(window).on("scroll load", function () {
      if ($(".navbar").offset().top > 20) {
        $(".fixed-top").addClass("top-nav-collapse");
      } else {
        $(".fixed-top").removeClass("top-nav-collapse");
      }
    });

    // jQuery for page scrolling feature - requires jQuery Easing plugin
    $(function () {
      $(document).on("click", "a.page-scroll", function (event) {
        var $anchor = $(this);
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $($anchor.attr("href")).offset().top,
            },
            600,
            "easeInOutExpo"
          );
        event.preventDefault();
      });
    });
    $(function () {
      $(document).on("click", "a.header-page-scroll", function (event) {
        event.preventDefault();
        var $target = $(".maincontent").children().first();
        $("html, body").stop().animate(
          {
            scrollTop: $target.offset().top,
          },
          600,
          "swing"
        );
      });
    });

    // closes the responsive menu on menu item click
    $(".navbar-nav li a").on("click", function (event) {
      if (!$(this).parent().hasClass("dropdown"))
        $(".navbar-collapse").collapse("hide");
    });

    /* Lightbox - Magnific Popup */
    $(".popup-with-move-anim").magnificPopup({
      type: "inline",
      fixedContentPos: false /* keep it false to avoid html tag shift with margin-right: 17px */,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-slide-bottom",
      callbacks: {
        open: function () {
          $("body").css("overflow", "hidden");
        },
        close: function () {
          $("body").css("overflow", "");
        },
      },
    });

    /* Filter - Isotope */
    var $grid = $(".grid").isotope({
      // options
      itemSelector: ".element-item",
      layoutMode: "fitRows",
    });

    // filter items on button click
    $(".filters-button-group").on("click", "a", function () {
      var filterValue = $(this).attr("data-filter");
      $grid.isotope({ filter: filterValue });
    });

    // change is-checked class on buttons
    $(".button-group").each(function (i, buttonGroup) {
      var $buttonGroup = $(buttonGroup);
      $buttonGroup.on("click", "a", function () {
        $buttonGroup.find(".is-checked").removeClass("is-checked");
        $(this).addClass("is-checked");
      });
    });

    /* Move Form Fields Label When User Types */
    // for input and textarea fields
    $("input, textarea").keyup(function () {
      if ($(this).val() != "") {
        $(this).addClass("notEmpty");
      } else {
        $(this).removeClass("notEmpty");
      }
    });

    /* Back To Top Button */
    // create the back to top button
    $("body").prepend(
      '<a href="body" class="scroll-to-top page-scroll">Back to Top</a>'
    );
    var amountScrolled = 700;
    $(window).scroll(function () {
      if ($(window).scrollTop() > amountScrolled) {
        $("a.scroll-to-top").fadeIn("500");
      } else {
        $("a.scroll-to-top").fadeOut("500");
      }
    });

    /* Removes Long Focus On Buttons */
    $(".button, a, button").mouseup(function () {
      $(this).blur();
    });

    $(".footer__modal").on("show.bs.modal", function (e) {
      window.location.hash = e.target.id;
    });
    $(".footer__modal").on("hide.bs.modal", function (e) {
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    });
  })(jQuery);
}
