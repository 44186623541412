import ballonSvg from "./svg/ballon";
import handshakeSvg from "./svg/handshake";
import lockSvg from "./svg/lock";
import targetSvg from "./svg/target";

const svgs = [
  { name: "target", svg: targetSvg },
  { name: "ballon", svg: ballonSvg },
  { name: "lock", svg: lockSvg },
  { name: "handshake", svg: handshakeSvg },
];

// add main svgs
const mainSvgMap = {
  0: {
    classes: "main-svg main-svg--left",
  },
  1: {
    classes: "main-svg main-svg--right",
  },
};

function generateMainSvgs() {
  $(".main-svg").remove();
  const availableSpaceHorizontal = $(".main-component")
    .has(".container")
    .first()
    .find(".container")
    .css("margin-left");

  $(".main-component").each(function () {
    const prevBGColor =
      $(this).prev().css("background-color") !== undefined
        ? $(this).prev().css("background-color")
        : $(this).next().css("background-color");
    const currBGColor = $(this).css("background-color");
    // console.log(prevBGColor);
    const mainComponentHeight = $(this).outerHeight();
    const setMainSVGClassesLevel = Math.floor(Math.random() * (2 - 0 + 1)) + 0;
    let setMainSVGClasses = undefined;
    // console.log(setMainSVGClassesLevel);

    // left
    if (setMainSVGClassesLevel === 0 || setMainSVGClassesLevel == 2) {
      setMainSVGClasses = mainSvgMap[0].classes;
    }

    // right
    if (setMainSVGClassesLevel === 1 || setMainSVGClassesLevel == 2) {
      setMainSVGClasses = mainSvgMap[1].classes;
    }

    const randomSVG = svgs[Math.floor(Math.random() * svgs.length)];
    let svg = $(randomSVG.svg).addClass(setMainSVGClasses);
    const svgHeight = Math.random() * (300 - 75) + 75;
    const svgOffsetTop =
      Math.random() * (mainComponentHeight - svgHeight - svgHeight) + svgHeight;
    svg.addClass("main-svg--" + randomSVG.name);
    svg.css("height", svgHeight + "px");
    svg.css("top", svgOffsetTop + "px");

    svg.find("path").css("fill", prevBGColor);

    $(this).prepend(svg);
  });
}

export default generateMainSvgs;
