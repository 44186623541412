import Mustache from "./mustache.min.js";
import $ from "jquery";

import scripts from "./scripts.js";
import generateMainSvgs from "./main-svg";

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

async function getTemplate(component, templates) {
  await $.ajax({
    url: "./component-" + component + ".html",
    cache: false,
    async: false,
    timeout: 500,
    success: function (data) {
      templates[component] = data;
    },
    error: function (err) {
      console.log(err);
    },
    dataType: "html",
  });
}

// VARS
// const domain = "https://versicherungsmakler-burgwedel.de";
let domain = window.location.origin;
// const domainDevAPI = "https://zielke-versicherungsmakler.de";
const domainDevAPI = "https://versicherungsmakler-burgwedel.de";
if (domain.includes("localhost")) {
  domain = domainDevAPI;
}
console.log(domain);
const templates = {};
let contactInformation = "";
let footerLinks = [];
// const navigation = [];
let navigationID = 0;
let headerCarouselInterval = 0;

// HEADER
function ajaxHeader() {
  return $.getJSON(domain + "/api/api/singletons/get/header", function (data) {
    console.log(data, "header");
    data.domain = domain; // adds base-domain to all templates

    data.images.forEach((image) => {
      $("<img />").attr("src", domain + image.value.image.path);
    });

    data.images[0].value.firstItem = true;
    data.images[0].value.active = true; // set first element as active for bootstrap carousel; all other element have no entry
    headerCarouselInterval = parseInt(data.interval);
    // data.images.forEach((item, index, arr) => {
    // if (!item.value.image) {
    //   delete item.value.image;
    // }
    // });

    getTemplate("header", templates);
    const template = templates.header;
    const html = Mustache.render(template, data);
    $(html).insertBefore(".maincontent");

    data.socialmedia.forEach((item) => {
      $(".nav-item.social-icons").append(`
        <div class="social-icons__icon">
          <a href="${item.value.link}" target="blank">
            <img src="./${item.value.plattform}.png" alt="">
          </a>
        </div>
        `);
    });
  });
}
// END HEADER
// FOOTER
function ajaxFooter() {
  return $.getJSON(domain + "/api/api/singletons/get/footer", function (data) {
    console.log(data, "footer");
    data.domain = domain; // adds base-domain to all templates
    data.links = data.links.filter((link) => link.value.active);
    footerLinks = data.links;
    contactInformation = data.contact;
    getTemplate("footer", templates);
    // const template = $('#header-component').html();
    const template = templates.footer;
    const html = Mustache.render(template, data);
    $(html).insertAfter(".maincontent");
  });
}
// END FOOTER

// MAIN
function ajaxMain() {
  return $.getJSON(
    domain + "/api/api/singletons/get/maincontent",
    { populate: 3 },
    function (data) {
      console.log(data.layout, "default");
      data.layout
        .filter((layoutEl) => layoutEl.component !== "text")
        .forEach((layoutEl) => {
          if (!templates[layoutEl.component]) {
            // console.warn('nicht drin')
            getTemplate(layoutEl.component, templates);
          } else {
            // console.warn('is drin')
          }
          // console.log(templates[layoutEl.component])

          // const template = $('#'+layoutEl.component+'-component').html();
          const template = templates[layoutEl.component];

          // TEXTMEDIA Extra
          if (layoutEl.component === "textmedia") {
            if (
              layoutEl.settings.text ||
              layoutEl.settings.headline ||
              layoutEl.settingstitle
            ) {
              layoutEl.settings.hasText = true;
            }
            if (!layoutEl.settings.image_small) {
              delete layoutEl.settings.image_small;
            }
            if (!layoutEl.settings.headlines_fix_on_top) {
              delete layoutEl.settings.headlines_fix_on_top;
            }
            switch (layoutEl.settings.image_align) {
              case "Bild rechts / Text links": {
                layoutEl.settings.image_align_class =
                  "custom-textmedia--img-right";
                break;
              }
              case "Bild links / Text rechts": {
                layoutEl.settings.image_align_class =
                  "custom-textmedia--img-left";
                break;
              }
              case "Vollbild / Text unten": {
                layoutEl.settings.image_align_class =
                  "custom-textmedia--img-full-text-bottom";
                break;
              }
              case "Vollbild / Text oben": {
                layoutEl.settings.image_align_class =
                  "custom-textmedia--img-full-text-top";
                break;
              }
            }

            // handle domain based image
            let currentImageToDisplay = null;
            if (
              layoutEl.settings.image_domains &&
              layoutEl.settings.image_domains.length > 0
            ) {
              const domainWithImage = layoutEl.settings.image_domains.find(
                (el) => el.value.domainName.display === domain
              );
              if (domainWithImage) {
                currentImageToDisplay = domainWithImage.value.image;
              }
            }
            if (!currentImageToDisplay) {
              currentImageToDisplay = layoutEl.settings.image;
            }
            if (currentImageToDisplay) {
              layoutEl.settings.currentImageToDisplay = currentImageToDisplay;
            }
          }
          // END TEXTMEDIA Extra

          // INFOBOX Extra
          if (layoutEl.component === "infobox") {
            layoutEl.settings.box = layoutEl.settings.box.filter(
              (box) => box.value.active
            );
          }
          // END INFOBOX Extra

          // SERVICES Extra
          if (layoutEl.component === "services") {
            layoutEl.settings.services = layoutEl.settings.services.filter(
              (service) => service.value.active
            );
            // layoutEl.settings.categories = layoutEl.settings.services.map(service => {
            layoutEl.settings.categories = layoutEl.settings.services.map(
              (service) => {
                return {
                  label: service.value.category.display,
                  categoryID: service.value.category.display
                    .toLowerCase()
                    .replace(/ /g, ""),
                };
              }
            );
            layoutEl.settings.categories = [
              ...new Map(
                layoutEl.settings.categories.map((item) => [
                  item["label"],
                  item,
                ])
              ).values(),
            ];
            layoutEl.settings.services.forEach((service, index) => {
              service.value.modalID = "service-" + index;
              if (service.value.showInAll === undefined) {
                service.value.showInAll = true;
              }
              if (!service.value.showInAll) {
                delete service.value.showInAll;
              }
              service.value.categoryID = layoutEl.settings.categories.find(
                (category) => category.label === service.value.category.display
              ).categoryID;
            });
            console.log(layoutEl.settings.categories);
            console.log(layoutEl.settings.services);
          }
          // END SERVICES Extra

          if (template !== undefined) {
            layoutEl.settings.domain = domain; // adds base-domain to all templates
            // check background color if not exists
            if (!layoutEl.settings.backgroundcolor) {
              layoutEl.settings.backgroundcolor = {};
              layoutEl.settings.backgroundcolor.color = "rgb(255,255,255)";
            }
            const html = Mustache.render(template, layoutEl.settings);
            $(html).appendTo(".maincontent");

            // handle Navigtion
            if (
              layoutEl.settings.navigation_active &&
              layoutEl.settings.navigation_title
            ) {
              // navigation.push({
              //   'title': layoutEl.settings.navigation_title,
              //   'id': navigationID
              // })
              $(".maincontent > div:not([data-no-anchor-target])")
                .last()
                .attr("id", "nav" + navigationID);
              $(".navbar-nav").append(`
              <li class="nav-item">
                <a class="nav-link page-scroll" href="#nav${navigationID}">${layoutEl.settings.navigation_title}</a>
              </li>
            `);
              navigationID++;
            }
          }
        });
      console.log(data.layout, "edited");
      // console.log(navigation, "navigation")
    }
  );
}

$.when(ajaxHeader(), ajaxMain(), ajaxFooter()).done((header, main) => {
  console.log("done");
  scripts();
  if (window.location.hash) {
    var hash = window.location.hash;
    $(hash).modal("toggle");
  }
  // init carousel
  $("#headerCarousel").addClass("carousel");
  $("#headerCarousel").carousel({
    interval: headerCarouselInterval,
    ride: "carousel",
  });

  // init contact form agreement footer links
  $(".js-contacts-agreement").each((index, el) => {
    let htmlStr = el.innerHTML;
    const matchedLinks = footerLinks.filter(
      (footerLink) => htmlStr.indexOf(footerLink.value.title) !== -1
    );
    matchedLinks.forEach((link) => {
      htmlStr = htmlStr.replace(
        link.value.title,
        `<a href="#" data-toggle="modal" data-target="#${link.value.title}">${link.value.title}</a>`
      );
    });
    $(el).html(htmlStr);
  });

  // contact form send
  $(".custom-contactform form").on("submit", function (e) {
    e.preventDefault();
    const submitBtn = $(this).find('button[type="submit"]');
    const submitBtnPrevText = submitBtn.text();

    submitBtn.attr("disabled", "disabled");
    submitBtn.text("Bitte Warten");

    $.post(
      domain +
        "/api/api/forms/submit/contactform?token=c168e78ddc02529aed4a3f1da35439",
      { form: Object.fromEntries(new URLSearchParams($(this).serialize())) },
      () => {
        $(".form-message__success").removeClass("d-none");
        $('input[name="agreed"]').prop("checked", false);
        $('input[name="name"]').val("");
        $('input[name="telefon"]').val("");
        $('textarea[name="text"]').val("");
        $('input[name="email"]').val("");
        setTimeout(() => {
          submitBtn.text(submitBtnPrevText);
          submitBtn.removeAttr("disabled");
        }, 2000);
      }
    ).fail(function () {
      $(".form-message__error").removeClass("d-none");
      submitBtn.text(submitBtnPrevText);
      submitBtn.removeAttr("disabled");
    });
  });

  // add eventListener for services -> show contact info
  $(".js-services-show-contact-info").on("click", function () {
    const checkMailRegEx =
      /\b([\w\d.-]+(@)[\w\d.-]+([dot]|(dot)|.)[\w\d.-])+\b/g;
    const checkAtRegEx = /\b(\s*ät\s*|\s*\(ät\)\s*|\s*\(&auml;t\)\s*)+\b/g;
    let updatedContactInformation = contactInformation.replace(
      checkAtRegEx,
      "@"
    );
    console.log(updatedContactInformation);
    const mail = updatedContactInformation.match(checkMailRegEx)[0];
    updatedContactInformation = updatedContactInformation.replace(
      mail,
      `<a href="mailto:${mail}">${mail}</a>`
    );
    console.log(mail);
    $(this).replaceWith(
      `<div class="services-contact-info">${updatedContactInformation}</div>`
    );
  });

  // initial click on services ("alle")
  $('[data-filter=".show-in-all"]').trigger("click");

  // add main svgs
  generateMainSvgs();

  window.addEventListener(
    "resize",
    debounce(() => {
      generateMainSvgs();
    }, 100)
  );
});
